import { Routes } from '@angular/router';
import { DeniedComponent } from '../denied/denied.component';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { SignInComponent } from '../sign-in/sign-in.component';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { TosComponent } from '../tos/tos.component';
import { AUTH_ROUTE_NAMES } from './auth-route-names';
import { AUTH_ROUTE_PARAMS } from './auth-route-params';

export const AUTHENTICATION_ROUTES: Routes = [
  {
    path: AUTH_ROUTE_NAMES.DEFAULT,
    component: SignInComponent,
  },
  {
    path: AUTH_ROUTE_NAMES.SIGN_IN,
    component: SignInComponent,
  },
  {
    path: AUTH_ROUTE_NAMES.SIGN_UP,
    component: SignUpComponent,
  },
  {
    path: AUTH_ROUTE_NAMES.RESET_PASSWORD,
    component: ResetPasswordComponent,
  },
  {
    path: AUTH_ROUTE_NAMES.TOS,
    component: TosComponent,
  },
  {
    path: AUTH_ROUTE_NAMES.DENIED,
    component: DeniedComponent,
  },
  {
    path: `${AUTH_ROUTE_NAMES.DENIED}/:${AUTH_ROUTE_PARAMS.USER_ROLE}`,
    component: DeniedComponent,
  },
];
