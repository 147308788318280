<nb-auth-block>
  <nb-card size="giant">
    <nb-card-header>
      <div class="header">
        <mk-logo size="tiny"></mk-logo>
        <h2>Terms Of Service</h2>
        <small>Your need to accept terms of service to start using MissionKeeper</small>
      </div>
    </nb-card-header>
    <nb-card-body>
      <iframe src="../../assets/tos/ksi-tos.html"></iframe>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="success" (click)="goToHome()">Accept</button>
    </nb-card-footer>
  </nb-card>
</nb-auth-block>
