import { OnDestroy } from '@angular/core';
import { ReplaySubject, Subscription } from 'rxjs';

export type Constructor<T> = new (...args: any[]) => T;

export class MixinRoot {
  log(x: any) {
    console.log(x);
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function Destroyable<T extends Constructor<{}>>(Base: T) {
  return class Mixin extends Base implements OnDestroy {
    private readonly subscriptions: Subscription[] = [];
    protected readonly destroyed$ = new ReplaySubject(1);

    protected registerSubscription(sub: Subscription) {
      this.subscriptions.push(sub);
    }

    public ngOnDestroy() {
      this.subscriptions.forEach((x) => x.unsubscribe());
      this.destroyed$.next();
      this.destroyed$.complete();
    }
  };
}
