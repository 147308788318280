export const e2eSelector = (e2eId: string) => {
  return `[e2e-id="${e2eId}"]`;
};

export const ids = {
  organizations: {
    add_organization_button: 'add-organization-button',
    edit_organization_button: 'edit-organization-button',
    delete_organization_button: 'delete-organization-button',
  },
  organization_form: {
    title: 'organization-title',
    description: 'organization-description',
    archives_enabled: 'archives-enabled',
    save_button: 'save-organization',
  },
  video_sources: {
    filter: 'filter',
    add_button: 'add-button',
    edit_button: 'edit-button',
    delete_button: 'delete-button',
  },
  video_source_form: {
    hls_url: 'hls-url',
    dash_url: 'dash-url',
    input_url: 'input-url',
    save_button: 'save-button',
  },
  confirm_dialog: {
    confirm: 'confirm',
  },
} as const;
