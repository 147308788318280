import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface AppConfig {
  firebaseConfig: { [key: string]: any };
  urlProtocol: string;
  emailEndpoint: string;
  emailSender: string;
  enableLogger?: 'true' | 'false';
  enableBoxMuxers?: 'true' | 'false';
  enableArchives?: 'true' | 'false';
  privacyPolicyUrl?: string;
  userGuideUrl?: string;
  firstNetAppCatalogLogo?: 'true' | 'false';
  version?: string;
  dynamicLinksUrlPrefix?: string;
  iosBundleId?: string;
  iosAppStoreId?: string;
  iosCustomScheme?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private appConfig: AppConfig | undefined;

  constructor(private http: HttpClient) {}

  public get firebaseConfig() {
    return this.appConfig!.firebaseConfig;
  }

  public get emailEndpoint() {
    return this.appConfig!.emailEndpoint;
  }

  public get emailSender() {
    return this.appConfig!.emailSender;
  }

  public get urlProtocol() {
    return this.appConfig!.urlProtocol;
  }

  public get loggerEnabled() {
    return this.appConfig!.enableLogger === 'true';
  }

  public get enableBoxMuxers() {
    return this.appConfig!.enableBoxMuxers === 'true';
  }

  public get enableArchives() {
    return this.appConfig!.enableArchives === 'true';
  }

  public get privacyPolicyUrl() {
    return this.appConfig!.privacyPolicyUrl;
  }

  public get userGuideUrl() {
    return this.appConfig!.userGuideUrl;
  }

  get firstNetAppCatalogLogo() {
    return this.appConfig!.firstNetAppCatalogLogo === 'true';
  }

  public get version() {
    return this.appConfig!.version;
  }

  public get dynamicLinksUrlPrefix() {
    return this.appConfig!.dynamicLinksUrlPrefix;
  }

  public get iosBundleId() {
    return this.appConfig!.iosBundleId;
  }

  public get iosAppStoreId() {
    return this.appConfig!.iosAppStoreId;
  }

  public get iosCustomScheme() {
    return this.appConfig!.iosCustomScheme;
  }

  async loadAppConfig() {
    const config = await this.http.get<AppConfig>('assets/config/config.json').toPromise();
    this.appConfig = config;
  }
}
