import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NbBadgeModule, NbIconModule } from '@nebular/theme';
import { NbTabComponent, NbTabsetComponent } from './tabset.component';

const NB_TABSET_COMPONENTS = [
  NbTabsetComponent,
  NbTabComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NbBadgeModule,
    NbIconModule,
  ],
  declarations: [
    ...NB_TABSET_COMPONENTS,
  ],
  exports: [
    ...NB_TABSET_COMPONENTS,
  ],
})
export class NbTabsetModule { }
