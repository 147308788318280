<nb-auth-block [nbSpinner]="submitted">
  <mk-logo size="small"></mk-logo>

  <h4 class="greeting">
    <span class="host">{{ invitingUserName$ | async }}</span> from
    <span class="organization">{{ organizationName$ | async }}</span> has invited you to join
    MissionKeeper
  </h4>

  <h2 class="title">Register</h2>

  <nb-alert *ngIf="errors?.length && !submitted" outline="danger" role="alert">
    <p class="alert-title"><b>Oh snap!</b></p>
    <ul class="alert-message-list">
      <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
    </ul>
  </nb-alert>

  <form (ngSubmit)="submit()" [formGroup]="form">
    <div class="form-control-group">
      <input
        name="fullName"
        [formControlName]="_.FULL_NAME"
        [placeholder]="_.FULL_NAME"
        autofocus
        nbInput
        [status]="getStatus(_.FULL_NAME)"
        fullWidth
        fieldSize="giant"
      />
      <p *ngIf="getError(_.FULL_NAME) as error" class="caption status-danger">
        {{ error }}
      </p>
    </div>

    <div class="form-control-group">
      <input
        name="email"
        [formControlName]="_.EMAIL"
        [placeholder]="_.EMAIL"
        nbInput
        [status]="getStatus(_.EMAIL)"
        fullWidth
        fieldSize="giant"
      />
      <p *ngIf="getError(_.EMAIL) as error" class="caption status-danger">
        {{ error }}
      </p>
    </div>

    <div class="form-control-group">
      <input
        name="password"
        type="password"
        [formControlName]="_.PASSWORD"
        [placeholder]="_.PASSWORD"
        nbInput
        [status]="getStatus(_.PASSWORD)"
        fullWidth
        fieldSize="giant"
      />
      <p *ngIf="getError(_.PASSWORD) as error" class="caption status-danger">
        {{ error }}
      </p>
    </div>

    <div class="form-control-group">
      <input
        name="passwordConfirm"
        type="password"
        [formControlName]="_.CONFIRM_PASSWORD"
        [placeholder]="_.CONFIRM_PASSWORD"
        nbInput
        [status]="getStatus(_.CONFIRM_PASSWORD)"
        fullWidth
        fieldSize="giant"
      />
      <p *ngIf="getError(_.CONFIRM_PASSWORD) as error" class="caption status-danger">
        {{ error }}
      </p>
    </div>

    <div class="form-control-group">
      <nb-checkbox name="terms" [formControlName]="_.TOS">
        Agree to
        <a class="tos-link" href="/assets/tos/ksi-tos.html" target="_blank">Terms & Conditions</a>
      </nb-checkbox>
    </div>

    <button nbButton status="success" size="giant" fullWidth [disabled]="submitted || !form.valid">
      Sign Up
    </button>
  </form>
</nb-auth-block>
