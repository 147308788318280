import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { combineLatest } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { filterForNotNullish } from '../../utils/is-not-null-or-undefined';
import { AppConfigService } from '../app.config.service';
import { Keyed } from '../models/keyed';
import { Mission } from '../models/mission';
import { OrganizationService } from './organization.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private serverUrl =
    this.appConfig.emailEndpoint || 'https://us-central1-missionkeeper-dev2.cloudfunctions.net/httpEmailInvitation';

  constructor(
    private appConfig: AppConfigService,
    private http: HttpClient,
    private org: OrganizationService,
    private user: UserService,
    private auth: AngularFireAuth
  ) {}

  public sendEmail(to: string | string[], mission: Keyed<Mission>) {
    return combineLatest([
      this.org.currentOrganization,
      this.user.getUsersDictionary().pipe(map(usersById => usersById[mission.owner])),
      this.auth.idToken.pipe(filterForNotNullish())
    ]).pipe(
      take(1),
      switchMap(([organization, owner, authToken]) => {
        const params = {
          to,
          from: this.appConfig.emailSender || 'admin@missionkeeper.io',
          subject: 'MissionKeeper: Invitation to join a Mission',
          content: `${location.protocol}//${location.hostname}/#/pages/mission/${mission.key}`,
          mission_id: mission.key,
          title: mission.title,
          author_id: owner?.uid,
          author_name: owner?.name,
          organization_id: organization.key,
          organization_title: organization.title,
          start: mission.start,
          end: mission.end || new Date().getTime(),
          location: mission.location ?? '',
          description: mission.description ?? '',
        };

        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
            Authorization: `Bearer ${authToken}`,
          }),
        };

        return this.http.post<string>(this.serverUrl, params, httpOptions)
      })
    ).subscribe(
      () => {},
      (err: any) => console.error(err)
    )
  }
}
