import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PAGES_URLS } from '../../pages/routing/pages-urls';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'mk-reset-password',
  styleUrls: ['./reset-password.component.scss', '../shared/titles.scss', '../shared/auth.scss'],
  templateUrl: './reset-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordComponent {
  submitted = false;
  errors: string[] = [];
  user = { email: '' };

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private title: Title,
    private ref: ChangeDetectorRef
  ) {
    this.title.setTitle('Reset password - Mission Keeper');
  }

  async requestPassword() {
    this.errors = [];
    this.submitted = true;

    try {
      await this.auth.resetPassword(this.user.email);
      await this.router.navigate(PAGES_URLS.DASHBOARD());
    } catch (err) {
      console.error(err);
      this.errors = [err];
    } finally {
      this.submitted = false;
      this.ref.markForCheck();
    }
  }
}
