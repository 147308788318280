import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppConfigService } from '../../../app.config.service';

@Component({
  selector: 'mk-footer',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <nb-actions size="small">
      <nb-action>Copyright &copy; {{ year }} KSI Data Sciences, all rights reserved</nb-action>
      <nb-action
        ><a [href]="appConfig.privacyPolicyUrl" target="_blank">Privacy Policy</a></nb-action
      >
      <nb-action><a [href]="appConfig.userGuideUrl" target="_blank">User Guide</a></nb-action>
    </nb-actions>

    <nb-actions>
      <nb-action *ngIf="appConfig.version">version {{ appConfig.version }}</nb-action>
    </nb-actions>
  `,
})
export class FooterComponent {
  year = new Date().getFullYear();

  constructor(public appConfig: AppConfigService) {}
}
