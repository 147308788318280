<nb-card>
  <nb-card-header>
    <div class="title">{{ title }}</div>
  </nb-card-header>
  <nb-card-body>
    <div class="message">{{ message }}</div>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton (click)="cancel()">{{ cancelText }}</button>
    <button [attr.e2e-id]="ids.confirm" nbButton status="danger" (click)="confirm()">
      {{ confirmText }}
    </button>
  </nb-card-footer>
</nb-card>
