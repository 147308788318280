<nb-auth-block>
  <h2 class="auth__title">Forgot Password</h2>
  <h5 class="auth__subtitle">
    Enter your email address and we’ll send a link to reset your password
  </h5>

  <nb-alert *ngIf="errors?.length && !submitted" outline="danger" role="alert">
    <p class="alert-title"><b>Oh snap!</b></p>
    <ul class="alert-message-list">
      <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
    </ul>
  </nb-alert>

  <form (ngSubmit)="requestPassword()" #requestPassForm="ngForm" aria-labelledby="title">
    <div class="form-control-group">
      <input
        nbInput
        [(ngModel)]="user.email"
        #email="ngModel"
        name="email"
        pattern=".+@.+\..+"
        placeholder="Email address"
        autofocus
        fullWidth
        fieldSize="giant"
        [status]="email.dirty ? (email.invalid ? 'danger' : 'success') : 'basic'"
        required
      />
      <ng-container *ngIf="email.invalid && email.touched">
        <p class="caption status-danger" *ngIf="email.errors?.required">Email is required!</p>
        <p class="caption status-danger" *ngIf="email.errors?.pattern">
          Email should be the real one!
        </p>
      </ng-container>
    </div>

    <button
      nbButton
      fullWidth
      status="primary"
      size="giant"
      [disabled]="submitted || !requestPassForm.valid"
    >
      Request password
    </button>
  </form>
</nb-auth-block>
