import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NbAuthComponent } from '@nebular/auth';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { AuthenticatedGuard } from './authentication/authenticated.guard';
import { AUTH_ROUTE_NAMES } from './authentication/routing/auth-route-names';
import { AUTHENTICATION_ROUTES } from './authentication/routing/authentication-routes';
import { PAGES_ROUTE_NAMES } from './pages/routing/pages-route-names';

const routes: Routes = [
  {
    path: AUTH_ROUTE_NAMES.ROOT,
    component: NbAuthComponent,
    children: AUTHENTICATION_ROUTES,
  },
  {
    path: PAGES_ROUTE_NAMES.ROOT,
    canActivate: [AuthenticatedGuard],
    loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: true,
  relativeLinkResolution: 'legacy',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config), NbEvaIconsModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
