import { Component } from '@angular/core';
import {
  NbMediaBreakpoint,
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';
import { delay, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Destroyable, MixinRoot } from '../../../utils/mixins';

// TODO: move layouts into the framework
@Component({
  selector: 'mk-layout',
  styleUrls: ['./layout.component.scss'],
  template: `
    <nb-layout>
      <nb-layout-header fixed>
        <mk-header position="normal"> </mk-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" state="collapsed">
        <ng-content select="[mkSideMenu]"></ng-content>
      </nb-sidebar>

      <nb-layout-column class="main-content">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <mk-footer></mk-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class LayoutComponent extends Destroyable(MixinRoot) {
  constructor(
    protected menuService: NbMenuService,
    protected themeService: NbThemeService,
    protected bpService: NbMediaBreakpointsService,
    protected sidebarService: NbSidebarService
  ) {
    super();

    const isBp = this.bpService.getByName('is');
    this.menuService
      .onItemSelect()
      .pipe(
        withLatestFrom(this.themeService.onMediaQueryChange()),
        delay(20),
        takeUntil(this.destroyed$)
      )
      .subscribe(([_, bp]: [any, NbMediaBreakpoint[]]) => {
        const [bpFrom, bpTo] = bp;
        if (bpTo.width <= isBp.width) {
          this.sidebarService.collapse('menu-sidebar');
        }
      });
  }
}
