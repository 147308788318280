import { UserRole } from './user-role';

interface UserRoleMetadataEntry {
  displayName: string;
  pluralName: string;
}

type UserRoleMetadata = Record<UserRole, UserRoleMetadataEntry>;
export const USER_ROLE_METADATA: UserRoleMetadata = {
  [UserRole.PendingApproval]: {
    displayName: 'Pending Approval',
    pluralName: 'Pending Approval',
  },
  [UserRole.Administrator]: {
    displayName: 'Administrator',
    pluralName: 'Administrators',
  },
  [UserRole.FullUser]: {
    displayName: 'Full User',
    pluralName: 'Full Users',
  },
  [UserRole.Guest]: {
    displayName: 'Guest',
    pluralName: 'Guests',
  },
  [UserRole.Superuser]: {
    displayName: 'Superuser',
    pluralName: 'Superusers',
  },
  [UserRole.Deleted]: {
    displayName: 'Deleted',
    pluralName: 'Deleted',
  },
};
