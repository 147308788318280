import { AngularFireAction, DatabaseSnapshot } from '@angular/fire/database';
import { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';
import { Keyed } from '../app/models/keyed';

/**
 * @param changes Data snapshot
 * @returns Array of entities with a key
 */
export function changesToKeyed<T>(changes: AngularFireAction<DatabaseSnapshot<T>>[]): Keyed<T>[] {
  return changes.map((c) => ({ ...c.payload.val()!, key: c.payload.key! }));
}

export function mapToEntityWithKey<T>(): OperatorFunction<
  AngularFireAction<DatabaseSnapshot<T>>,
  Keyed<T>
> {
  return (source) =>
    source.pipe(map((snapshot) => ({ ...snapshot.payload.val()!, key: snapshot.payload.key! })));
}
