import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { map, share } from 'rxjs/operators';
import { UserRole } from '../../models/user';
import { AUTH_ROUTE_PARAMS } from '../routing/auth-route-params';
import { AUTH_URLS } from '../routing/auth-urls';

@Component({
  selector: 'mk-denied',
  styleUrls: ['./denied.component.scss', '../shared/titles.scss', '../shared/auth.scss'],
  templateUrl: './denied.component.html',
})
export class DeniedComponent {
  userRole$ = this.route.paramMap.pipe(
    map((p) => p.get(AUTH_ROUTE_PARAMS.USER_ROLE) as UserRole | null),
    share()
  );
  deleted = UserRole.Deleted;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AngularFireAuth,
    private title: Title
  ) {
    this.title.setTitle('Access denied - Mission Keeper');
  }

  async goToSignIn() {
    await this.auth.signOut();
    await this.router.navigate(AUTH_URLS.SIGN_IN());
  }
}
