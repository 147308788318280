import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { filterForNotNullish } from '../../utils/is-not-null-or-undefined';
import { EditMissionResult } from '../components/mission-editor/edit-mission-result';
import { Dict } from '../models/dict';
import { MissionWithKey } from '../models/keyed';
import { Mission, MissionGroup, MissionStream, MissionUser } from '../models/mission';
import { UserStatus } from '../models/user';
import { UserMissions } from '../models/user-mission';
import { DatabaseService } from './database.service';
import { EmailService } from './email.service';
import { FetchMissionService } from './fetch-mission.service';
import { GroupService } from './group.service';
import { OrganizationService } from './organization.service';
import { ProfileService } from './profile.service';
import { UserService } from './user.service';

export interface MissionParticipant {
  id: string;
  name: string;
  status: UserStatus;
  type: 'invitee' | 'user' | 'group';
  mission: string;
}

export type NullableMissionParticipant = {
  [P in keyof MissionParticipant]: MissionParticipant[P] | null;
};

@Injectable({
  providedIn: 'root',
})
export class MissionService {
  constructor(
    private profile: ProfileService,
    private db: DatabaseService,
    private fetchMisison: FetchMissionService,
    private email: EmailService,
    private userService: UserService,
    private groupService: GroupService,
    private organization: OrganizationService
  ) {}

  async addMission(result: EditMissionResult, missionId: string | null) {
    const userId = (await this.getUserId()) as string; // TODO: remove as assertion

    const groupsObj = result.groups.reduce<Dict<MissionGroup>>((users, user) => {
      users[user.item_id] = { status: 'participant' };
      return users;
    }, {});

    const usersObj = result.users.reduce<Dict<MissionUser>>((users, user) => {
      users[user.item_id] = { status: 'participant' };
      return users;
    }, {});

    let streamsObj = result.selectedBoxStreams.reduce<Dict<MissionStream>>((streams, stream) => {
      streams[stream.item_id] = { muxer: stream.item_id };
      return streams;
    }, {});

    streamsObj = { ...streamsObj, ...result.selectedMobileStreams };

    const dbobj: Mission = {
      title: result.title ?? '',
      location: result.location ? result.location : '',
      description: result.description ?? '',
      archivesEnabled: result.archivesEnabled,
      start: result.startDate.getTime(),
      end: result.endDate.getTime(),
      streams: streamsObj,
      users: usersObj,
      groups: groupsObj,
      owner: userId,
      organization: result.organization ? result.organization.item_id : '',

      // TODO: Remove meta when possible
      meta: {
        user: userId, // WARN: user is used in removeMission. Don't forget to change it there
        location: result.location ? result.location : '',
        description: result.description,
        streams: result.selectedBoxStreams, // WARN: doesnt contain mobile streams; this property is just for backward compat
      },
    };

    // even if event changed, always send update
    const userlistNew = result.users.map((u) => u.item_id);
    const grouplistNew = result.groups.map((u) => u.item_id);

    // if new mission
    if (missionId === null) {
      missionId = this.db.missions.add(dbobj).key as string; // TODO: remove as assertion

      // send to whole list
      if (userlistNew.length > 0) {
        this.sendEmails({...dbobj, key: missionId}, userlistNew);
      }

      // send invites to groups if any
      if (grouplistNew.length > 0) {
        this.sendGroupEmails({...dbobj, key: missionId}, grouplistNew);
      }
    } else {
      const existingMissionId = missionId;
      this.db
        .missions.get(existingMissionId).valueChanges()
        .pipe(take(1))
        .subscribe((mission) => {
          const userIds = mission?.users
            ? userlistNew.filter((uid) => mission?.users[uid] == null)
            : userlistNew;

          // there are un-invited users in list
          if (userIds.length > 0) {
            this.sendEmails({...mission!, key: existingMissionId}, userIds);
          }

          this.db.missions.update(existingMissionId, dbobj);
        });

      this.db
        .missions.get(existingMissionId).valueChanges()
        .pipe(take(1))
        .subscribe((mission) => {
          const groupIds = mission?.groups
            ? grouplistNew.filter((id) => mission.groups[id] == null)
            : grouplistNew;

          // there are un-invited users in list
          if (groupIds.length > 0) {
            this.sendGroupEmails({...mission!, key: existingMissionId}, groupIds);
          }

          this.db.missions.update(existingMissionId, dbobj);
        });
    }

    // Update user missions
    await this.db.user(userId).mission(missionId).set();
    await Promise.all(
      result.users.map(({ item_id }) => this.db.user(item_id).mission(missionId!).set())
    );
  }

  removeMission(missionId: string) {
    this.db.missions
      .get(missionId)
      .valueChanges()
      .pipe(take(1))
      // eslint-disable-next-line rxjs/no-async-subscribe
      .subscribe(async (mission) => {
        await this.db.user(mission?.owner!).mission(missionId).remove();
        await Promise.all(
          Object.keys(mission?.users ?? {}).map((userId) =>
            this.db.user(userId).mission(missionId).remove()
          )
        );
      });

    this.db.missions.remove(missionId);
  }

  public activateMission(missionId: string, active: boolean) {
    this.db.missions.update(missionId, {
      active,
    });
  }

  getUserMissionIds(): Observable<UserMissions> {
    return this.profile.userId.pipe(
      filterForNotNullish(),
      switchMap((userId) => this.db.user(userId).missions()),
      map((userMissions) => userMissions ?? {})
    );
  }

  getUserMissions(): Observable<MissionWithKey[]> {
    const allUserMisssions = this.profile.userId.pipe(
      filterForNotNullish(),
      switchMap((userId) => this.db.user(userId).missions()),
      map((userMissions) => {
        const missionIds = Object.keys(userMissions ?? {});
        return missionIds.map((id) => this.fetchMisison.getMission(id));
      }),
      switchMap((getMissions$) => combineLatest(getMissions$))
    );

    return combineLatest([allUserMisssions, this.organization.currentOrganizationId]).pipe(
      map(([missions, orgId]) => missions.filter((m) => m.organization === orgId))
    );
  }

  private sendEmails(mission: MissionWithKey, userIds: string[]) {
    this.userService
      .getUsersDictionary()
      .pipe(take(1))
      .subscribe((allUsersById) => {
        const emails = userIds
          .map((uid) => allUsersById[uid])
          .filter((user) => user != null)
          .map((user) => user.email);
        this.email.sendEmail(emails, mission);
      });
  }

  private sendGroupEmails(mission: MissionWithKey, userIds: string[]) {
    this.groupService
      .getAllGroups()
      .pipe(take(1))
      .subscribe((groups) => {
        for (const group in groups) {
          if (userIds.indexOf(group) >= 0) {
            const userList = groups[group].users;
            const userids: string[] = [];
            // eslint-disable-next-line guard-for-in
            for (const usr in userList) {
              userids.push(usr);
            }

            this.userService
              .getUsersDictionary()
              .pipe(take(1))
              // eslint-disable-next-line rxjs/no-nested-subscribe
              .subscribe((allUsersById) => {
                const emails = userids
                  .map((uid) => allUsersById[uid])
                  .filter((user) => user != null)
                  .map((user) => user.email);

                this.email.sendEmail(emails, mission);
              });
          }
        }
      });
  }

  private getUserId() {
    return this.profile.userId.pipe(take(1)).toPromise();
  }
}
