import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NbComponentStatus } from '@nebular/theme';
import { map, shareReplay, take, takeUntil } from 'rxjs/operators';
import { Destroyable, MixinRoot } from '../../../utils/mixins';
import { AppConfigService } from '../../app.config.service';
import { PAGES_URLS } from '../../pages/routing/pages-urls';
import { APP_URLS } from '../../routing/app-urls';
import { AuthenticationService } from '../authentication.service';
import { formConfig } from '../form-config';
import { AUTH_ROUTE_QUERY_PARAMS } from '../routing/auth-route-query-params';

enum _ {
  'FULL_NAME' = 'Full Name',
  'EMAIL' = 'Email',
  'PASSWORD' = 'Password',
  'CONFIRM_PASSWORD' = 'Confirm Password',
  'TOS' = 'Terms of service',
}

const MISSMATCHING_PASWORDS = 'MISSMATCHING_PASWORDS';

@Component({
  selector: 'mk-sign-up',
  styleUrls: ['./sign-up.component.scss', '../shared/auth.scss'],
  templateUrl: './sign-up.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpComponent extends Destroyable(MixinRoot) implements OnInit {
  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    public appConfig: AppConfigService,
    private title: Title,
    private ref: ChangeDetectorRef
  ) {
    super();
    this.title.setTitle('Sign up - Mission Keeper');
  }

  _ = _;

  submitted = false;
  errors: string[] = [];

  sameAsPasswordValidator(control: AbstractControl): ValidationErrors | null {
    return control.value === this.form?.get(_.PASSWORD)?.value
      ? null
      : { [MISSMATCHING_PASWORDS]: true };
  }

  form: FormGroup = new FormGroup({
    [_.FULL_NAME]: new FormControl('', [
      Validators.required,
      Validators.minLength(formConfig.validation.fullName.minLength),
      Validators.maxLength(formConfig.validation.fullName.maxLength),
    ]),
    [_.EMAIL]: new FormControl('', [Validators.required, Validators.pattern(/.+@.+\..+/)]),
    [_.PASSWORD]: new FormControl('', [
      Validators.required,
      Validators.minLength(formConfig.validation.password.minLength),
      Validators.maxLength(formConfig.validation.password.maxLength),
    ]),
    [_.CONFIRM_PASSWORD]: new FormControl('', [
      Validators.required,
      this.sameAsPasswordValidator.bind(this),
    ]),
    [_.TOS]: new FormControl(false, [Validators.requiredTrue]),
  });

  invitingUserName$ = this.route.queryParamMap.pipe(
    map((p) => p.get(AUTH_ROUTE_QUERY_PARAMS.INVITING_USER_NAME))
  );
  organizationName$ = this.route.queryParamMap.pipe(
    map((p) => p.get(AUTH_ROUTE_QUERY_PARAMS.ORGANIZATION_NAME))
  );
  organizationId$ = this.route.queryParamMap.pipe(
    map(
      (p) => p.get(AUTH_ROUTE_QUERY_PARAMS.ORGANIZATION_ID),
      shareReplay({ bufferSize: 1, refCount: true })
    )
  );
  missionId$ = this.route.queryParamMap.pipe(
    map(
      (p) => p.get(AUTH_ROUTE_QUERY_PARAMS.MISSION_ID),
      shareReplay({ bufferSize: 1, refCount: true })
    )
  );

  getStatus(name: _): NbComponentStatus {
    const control = this.form.get(name);
    return control?.invalid && control.touched ? 'danger' : 'success';
  }

  getError(name: _): string | null {
    const control = this.form.get(name);
    if (control?.invalid && control.touched) {
      if (control.errors?.required) {
        return `${name} is required!`;
      }
      if (control.errors?.minlength) {
        return `${name} should be at least ${control.errors?.minlength.requiredLength} characters`;
      }
      if (control.errors?.maxlength) {
        return `${name} should be at most ${control.errors?.maxlength.requiredLength} characters`;
      }
      if (control.errors?.pattern) {
        return `${name} should be the real one!`;
      }
      if (control.errors?.[MISSMATCHING_PASWORDS]) {
        return 'Password does not match the confirm password.';
      }
    }
    return null;
  }

  ngOnInit() {
    this.form
      .get(_.PASSWORD)
      ?.valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.form.get(_.CONFIRM_PASSWORD)?.updateValueAndValidity();
      });
  }

  async submit() {
    this.errors = [];
    this.submitted = true;

    const orgId = await this.organizationId$.pipe(take(1)).toPromise();
    const missionId = await this.missionId$.pipe(take(1)).toPromise();

    try {
      await this.auth.signUp(
        this.form.controls[_.EMAIL].value,
        this.form.controls[_.PASSWORD].value,
        this.form.controls[_.FULL_NAME].value,
        orgId!,
        missionId!
      );
      const queryParams = { [AUTH_ROUTE_QUERY_PARAMS.MISSION_ID]: missionId };
      if (missionId) {
        await this.router.navigate(PAGES_URLS.MISSION(missionId), { queryParams });
      } else {
        await this.router.navigate(APP_URLS.ROOT(), { queryParams });
      }
    } catch (err) {
      console.error(err);
      this.errors = [err];
    } finally {
      this.submitted = false;
      this.ref.markForCheck();
    }
  }
}
