import { PAGES_ROUTE_NAMES } from './pages-route-names';

export const PAGES_URLS = {
  DASHBOARD(): any[] {
    return [PAGES_ROUTE_NAMES.ROOT, PAGES_ROUTE_NAMES.DASHBOARD];
  },
  MISSION(id: string) {
    return [PAGES_ROUTE_NAMES.ROOT, PAGES_ROUTE_NAMES.MISSION, id];
  },
} as const;
