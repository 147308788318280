import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { filter, first, pairwise } from 'rxjs/operators';
import { isNotNullOrUndefined } from '../../utils/is-not-null-or-undefined';
import { AppConfigService } from '../app.config.service';
import { UserRole } from '../models/user';
import { DatabaseService } from '../services/database.service';
import { ProfileService } from '../services/profile.service';
import { AUTH_ROUTE_QUERY_PARAMS } from './routing/auth-route-query-params';
import { AUTH_URLS } from './routing/auth-urls';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private auth: AngularFireAuth,
    private db: DatabaseService,
    private profile: ProfileService,
    private appConfig: AppConfigService,
    private router: Router
  ) {
    this.auth.user
      .pipe(
        pairwise(),
        filter(([fromUser, toUser]) => !!fromUser && toUser == null)
      )
      .subscribe(() => {
        this.router.navigate(AUTH_URLS.SIGN_IN());
      });
  }

  signIn(email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  updateSignUpData(uid: string, organizationId: string, missionId?: string) {
    const userRole = missionId ? UserRole.Guest : UserRole.PendingApproval;
    return Promise.all([
      this.db.user(uid).organization(organizationId).setRole(userRole),
      this.db.organization(organizationId).user(uid).set(),
      missionId ? this.db.user(uid).mission(missionId).set() : Promise.resolve(),
    ]);
  }

  async signUp(
    email: string,
    password: string,
    fullName: string,
    organizationId: string,
    missionId?: string
  ) {
    const authMethods = await this.auth.fetchSignInMethodsForEmail(email);
    const alreadyRegistered = authMethods.some((m) => m === 'password');

    if (alreadyRegistered) {
      this.router.navigate(AUTH_URLS.SIGN_IN(), {
        queryParams: {
          [AUTH_ROUTE_QUERY_PARAMS.MESSAGE]: `We've detected you already have an account with Mission Keeper.`,
          [AUTH_ROUTE_QUERY_PARAMS.MISSION_ID]: missionId,
          [AUTH_ROUTE_QUERY_PARAMS.ORGANIZATION_ID]: organizationId,
          [AUTH_ROUTE_QUERY_PARAMS.ALREADY_REGISTERED]: true,
        },
      });
    } else {
      const { user } = await this.auth.createUserWithEmailAndPassword(email, password);
      if (user == null) {
        throw new Error('Sign up failed');
      }
      await user.updateProfile({
        displayName: fullName,
        photoURL: '',
      });
      await this.db.users.update({
        uid: user.uid,
        name: fullName,
        email,
        tos: true,
      });
      await this.updateSignUpData(user.uid, organizationId, missionId); // TODO: remove ! assertion
    }
  }

  logout() {
    return this.auth.signOut();
  }

  resetPassword(email: string) {
    return this.auth.sendPasswordResetEmail(email);
  }

  async acceptTos() {
    const uid = await this.profile.userId.pipe(first(isNotNullOrUndefined)).toPromise();
    return this.db.users.update({
      uid,
      tos: true,
    });
  }
}
