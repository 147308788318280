import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { chain, first, isEmpty, isEqual } from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { filterForNotNullish, isNotNullOrUndefined } from '../../utils/is-not-null-or-undefined';
import { AppConfigService } from '../app.config.service';
import { Dict } from '../models/dict';
import { Keyed, MuxerWithKey } from '../models/keyed';
import { MissionStream } from '../models/mission';
import { Muxer } from '../models/muxer';
import { DatabaseService } from './database.service';
import { FetchMissionService } from './fetch-mission.service';
import { UserService } from './user.service';

type MissionStreamWithStreamStart = Omit<MissionStream, 'streamStart'> &
  Required<Pick<MissionStream, 'streamStart'>>;
function hasStreamsStart(e: MissionStream): e is Keyed<MissionStreamWithStreamStart> {
  return !!e.streamStart;
}

export interface VideoStreamModel {
  id: string;
  dashUrl: string;
  hlsUrl: string;
  streamStart: number;
  streamEnd?: number;
  author: string;
  device: string;
  isActive: boolean;
  streamType: 'h264elementary' | 'mpegts' | undefined;
}

export class VideoSourceModel {
  constructor(
    public readonly author: string,
    public type: 'box' | 'mobile',
    public readonly title: string,
    public readonly device: string | null,
    public readonly streams: VideoStreamModel[]
  ) {}

  public get isActive(): boolean {
    return this.streams.some((stream) => stream.isActive);
  }
}

@Injectable({
  providedIn: 'root',
})
export class MuxerService {
  private protocol: string = this.appConfig.urlProtocol;
  private enableBoxMuxers: boolean = this.appConfig.enableBoxMuxers;

  constructor(
    private appConfig: AppConfigService,
    private db: DatabaseService,
    private auth: AngularFireAuth,
    private http: HttpClient,
    private fetchMissionService: FetchMissionService,
    private userService: UserService
  ) {}

  create(muxer: Muxer) {
    return this.db.muxer.add({
      dashUrl: muxer.dashUrl,
      hlsUrl: muxer.hlsUrl,
      inputUrl: muxer.inputUrl,
    });
  }

  update(muxer: MuxerWithKey) {
    return this.db.muxer.update(muxer.key, {
      dashUrl: muxer.dashUrl,
      hlsUrl: muxer.hlsUrl,
      inputUrl: muxer.inputUrl,
    });
  }

  remove(muxerId: string): Promise<void> {
    return this.db.muxer.remove(muxerId);
  }

  getBoxMuxers(): Observable<Dict<Muxer>> {
    return this.db.muxer.getDict().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  removeStream(missionId: string, streamId: string) {
    return this.auth.idToken.pipe(
      take(1),
      filterForNotNullish(),
      switchMap((token) =>
        this.http.delete(`${location.origin}/muxers/missions/${missionId}/streams`, {
          headers: {
            ['Authorization']: `Bearer ${token}`,
          },
          params: new HttpParams().set('streamId', streamId),
        })
      )
    );
  }

  downloadVideo(missionId: string, streamId: string) {
    return this.auth.idToken.pipe(
      take(1),
      filterForNotNullish(),
      switchMap((token) =>
        this.http.get(`${location.origin}/muxers/missions/${missionId}/streams/${streamId}/video`, {
          headers: {
            ['Authorization']: `Bearer ${token}`,
          },
          responseType: 'blob',
        })
      )
    );
  }

  getMissionVideoSources(missionId: string): Observable<VideoSourceModel[]> {
    const mission$ = this.fetchMissionService.getMission(missionId);
    const users$ = this.userService.getUserList();
    const boxMuxers$ = this.getBoxMuxers();

    return combineLatest([mission$, users$, boxMuxers$]).pipe(
      distinctUntilChanged((a, b) => isEqual(a[0].streams, b[0].streams)),
      map(([mission, users, boxMuxers]) => {
        const boxAndMobileStreams = Object.entries(mission.streams || [])
          .map<Keyed<MissionStream>>(([id, stream]) => ({ key: id, ...stream }))
          .filter((s) => s.deleted == null || s.deleted === false);

        const boxStreams = boxAndMobileStreams.filter((stream) => stream.muxer);
        const mobileStreams = boxAndMobileStreams
          .filter((stream) => !stream.muxer)
          .filter(hasStreamsStart);

        const boxSources = boxStreams.map((stream) => {
          const boxMuxer = boxMuxers[stream.muxer!]; // TODO: remove ! assertion
          return new VideoSourceModel(
            stream.muxer as string,
            'box',
            `MC (${boxMuxer.inputUrl})`,
            `Box Muxer`,
            [
              {
                id: stream.key,
                dashUrl: `${this.protocol}://${boxMuxer.dashUrl}`,
                hlsUrl: `${this.protocol}://${boxMuxer.hlsUrl}`,
                streamStart: stream.streamStart || 0,
                streamEnd: stream.streamEnd,
                author: stream.muxer as string,
                device: 'Box',
                isActive: !!stream.isActive,
                streamType: stream.streamType,
              },
            ]
          );
        });

        const mobileSources = chain(mobileStreams)
          .filter((stream) => !!stream.author)
          .groupBy((stream) => `${stream.author}${stream.sourceDevice}`)
          .values()
          .filter((streamsByDevice) => !isEmpty(streamsByDevice))
          .map<VideoSourceModel>((streamsByDevice) => {
            const firstStream = first(streamsByDevice)!;
            const author = firstStream.author!;
            const authorName = users.find((u) => u.uid === firstStream.author)?.name ?? 'Unknown';
            const sourceDevice = firstStream.sourceDevice ?? null;

            const streams = streamsByDevice
              .sort((stream1, stream2) => stream2.streamStart - stream1.streamStart)
              .map<VideoStreamModel>((stream) => ({
                id: stream.key,
                dashUrl: `${this.protocol}://${stream.dashUrl}`,
                hlsUrl: `${this.protocol}://${stream.hlsUrl}`,
                streamStart: stream.streamStart,
                streamEnd: stream.streamEnd,
                author,
                device: sourceDevice ?? 'Unknown device',
                isActive: !!stream.isActive,
                streamType: stream.streamType,
              }));

            return new VideoSourceModel(author, 'mobile', authorName, sourceDevice, streams);
          })
          .value();

        return [...(this.enableBoxMuxers ? boxSources : []), ...mobileSources]
          .filter(isNotNullOrUndefined)
          .sort((s1, s2) => s1.title.localeCompare(s2.title));
      })
    );
  }
}
