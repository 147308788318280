import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { PAGES_URLS } from '../../pages/routing/pages-urls';
import { APP_URLS } from '../../routing/app-urls';
import { AuthenticationService } from '../authentication.service';
import { AUTH_ROUTE_QUERY_PARAMS } from '../routing/auth-route-query-params';

@Component({
  selector: 'mk-tos',
  styleUrls: ['./tos.component.scss', '../shared/auth.scss'],
  templateUrl: './tos.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TosComponent {
  public missionId$: Observable<string | undefined>;

  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private title: Title
  ) {
    this.title.setTitle('Terms of service - Mission Keeper');

    this.missionId$ = this.route.queryParams.pipe(
      map((params) => params[AUTH_ROUTE_QUERY_PARAMS.MISSION_ID])
    );
  }

  async goToHome() {
    await this.auth.acceptTos();

    combineLatest([
      this.route.queryParams.pipe(map((params) => params[AUTH_ROUTE_QUERY_PARAMS.MISSION_ID])),
      this.route.queryParams.pipe(map((params) => params[AUTH_ROUTE_QUERY_PARAMS.RETURN_URL])),
    ])
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(([missionId, returnUrl]) => {
        if (missionId) {
          this.router.navigate(PAGES_URLS.MISSION(missionId));
        } else if (returnUrl) {
          this.router.navigateByUrl(returnUrl);
        } else {
          this.router.navigate(APP_URLS.ROOT());
        }
      });
  }
}
