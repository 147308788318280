import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Dict } from '../models/dict';
import { Group } from '../models/group';
import { Keyed } from '../models/keyed';
import { DatabaseService } from './database.service';
import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  constructor(private db: DatabaseService, private profile: ProfileService) {}

  async create(group: Group, orgId: string): Promise<any> {
    const { key } = await this.db.groups.add({
      name: group.name,
      organizationId: orgId,
      users: group.users ? group.users : {},
    });
    return this.db.organization(orgId).group(key!).set()
  }

  update(group: Keyed<Group>): Promise<void> {
    return this.db.groups.update(group.key, {
      name: group.name,
      organizationId: group.organizationId,
      users: group.users,
    });
  }

  async remove(groupId: string, orgId: string): Promise<void> {
    await this.db.organization(orgId).group(groupId).remove()
    await this.db.groups.remove(groupId);
  }

  getAllGroups(): Observable<Dict<Group>> {
    return this.db.groups
      .getDict()
      .pipe(map((orgs) => orgs ?? {}));
  }

  // TODO: it was probably broken!
  // getUserGroups(): Observable<Keyed<Group>[]> {
  //   const userOrganizationIds = this.profile.userId.pipe(
  //     filter((u) => !!u),
  //     //  switchMap(userId => this.db.object<UserOrganizations>(`/users/${userId}/groups`).valueChanges()),
  //     switchMap((userId) => this.db.object<UserOrganizations>(`/groups`).valueChanges()),
  //     map((userOrgs) => (userOrgs ? Object.keys(userOrgs) : []))
  //   );

  //   return combineLatest([this.getAllOrganizations(), userOrganizationIds]).pipe(
  //     map(([allOrgs, userOrgIds]) => {
  //       return userOrgIds.map((id) => ({ key: id, ...allOrgs[id] }));
  //     })
  //   );
  // }
}
