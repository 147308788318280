import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { NbComponentSize } from '@nebular/theme';
import { AppConfigService } from '../app.config.service';

@Component({
  selector: 'mk-logo',
  template: `
    <img src="../../../assets/images/mk-logo-large.png" />
    <img
      *ngIf="appConfig.firstNetAppCatalogLogo"
      src="../../../assets/images/attfn_pos.png"
      class="fn-logo"
    />
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
      }

      :host.size-tiny {
        width: 80px;
      }
      :host.size-small {
        width: 160px;
      }
      :host.size-medium {
        width: 220px;
      }
      :host.size-large {
        width: 280px;
      }
      :host.size-giant {
        width: 330px;
      }

      img {
        max-width: 100%;
      }
      .fn-logo {
        margin-top: 1em;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MkLogoComponent implements OnInit {
  constructor(public appConfig: AppConfigService) {}

  @Input() size: NbComponentSize = 'medium';

  @HostBinding('class.size-tiny')
  get tiny() {
    return this.size === 'tiny';
  }

  @HostBinding('class.size-small')
  get small() {
    return this.size === 'small';
  }

  @HostBinding('class.size-medium')
  get medium() {
    return this.size === 'medium';
  }

  @HostBinding('class.size-large')
  get large() {
    return this.size === 'large';
  }

  @HostBinding('class.size-giant')
  get giant() {
    return this.size === 'giant';
  }

  ngOnInit() {}
}
