import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationExtras,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { take } from 'rxjs/operators';
import { ProfileService } from '../services/profile.service';
import { AUTH_ROUTE_QUERY_PARAMS } from './routing/auth-route-query-params';
import { AUTH_URLS } from './routing/auth-urls';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivate {
  constructor(
    private auth: AngularFireAuth,
    private profile: ProfileService,
    private router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const extras: NavigationExtras = {
      queryParams: { [AUTH_ROUTE_QUERY_PARAMS.RETURN_URL]: state.url },
    };

    const firebaseUser = await this.auth.user.pipe(take(1)).toPromise();
    if (firebaseUser == null) {
      return this.router.createUrlTree(AUTH_URLS.SIGN_IN(), extras);
    }

    const user = await this.profile.user.pipe(take(1)).toPromise();
    if (!user?.tos) {
      return this.router.createUrlTree(AUTH_URLS.TOS(), extras);
    }

    return true;
  }
}
