import { Component } from '@angular/core';
import { NbIconLibraries } from '@nebular/theme';
import { PresenceService } from './services/presense.service';

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {
  constructor(public presence: PresenceService, private iconLibraries: NbIconLibraries) {
    this.iconLibraries.registerSvgPack('mk', {
      sidebar: `<svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        class="eva"
        fill="currentColor">
          <g transform="matrix(0.01048578,0,0,-0.01048578,-1.3679795,25.457878)">
            <path d="m 415,2226 c -41,-18 -83,-69 -90,-109 -3,-18 -5,-406 -3,-864 3,-822 3,-832 24,-859 11,-15 33,-37 48,-48 27,-21 34,-21 886,-21 852,0 859,0 886,21 15,11 37,33 48,48 21,27 21,34 21,886 0,852 0,859 -21,886 -11,15 -33,37 -48,48 -27,21 -36,21 -874,23 -689,2 -853,0 -877,-11 z M 2080,1280 V 480 H 1280 480 v 800 800 h 800 800 z"/>
            <path d="M 640,1280 V 640 h 240 240 v 640 640 H 880 640 Z" />
          </g>
    </svg> `,
    });
  }
}
