import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, map, share, shareReplay, switchMap } from 'rxjs/operators';
import { AppConfigService } from '../app.config.service';
import { MissionWithKey } from '../models/keyed';
import { FetchMissionService } from './fetch-mission.service';
import { MuxerService, VideoSourceModel, VideoStreamModel } from './muxer.service';
import { OrganizationService } from './organization.service';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private currentMissionId = new ReplaySubject<string>(1);

  constructor(
    private fetchMission: FetchMissionService,
    private organization: OrganizationService,
    private muxer: MuxerService,
    private appConfig: AppConfigService
  ) {}

  archivesEnabled = () =>
    combineLatest([
      of(this.appConfig.enableArchives),
      this.organization.currentOrganization.pipe(map((o) => o.archivesEnabled)),
      this.getCurrentMission().pipe(map((m) => m.archivesEnabled)),
    ]).pipe(
      map(([configEnableArchives, orgEnableArchives, missionEnableArchives]) => {
        if (configEnableArchives === false) {
          return false;
        }
        return missionEnableArchives ?? orgEnableArchives ?? configEnableArchives;
      }),
      distinctUntilChanged()
    );

  // Mission
  getCurrentMissionId = () => this.currentMissionId.asObservable();
  getCurrentMission(): Observable<MissionWithKey> {
    return this.currentMissionId.pipe(switchMap((id) => this.fetchMission.getMission(id)));
  }
  setCurrentMission(missionId: string): void {
    this.currentMissionId.next(missionId);
  }
  get isCurrentMissionActive() {
    return this.getCurrentMission().pipe(
      distinctUntilChanged((m1, m2) => m1.active === m2.active),
      map((m) => !!m.active)
    );
  }

  // Source and Stream
  private _videoStream = new ReplaySubject<VideoStreamModel | null>(1);
  get videoStream() {
    return this._videoStream.asObservable();
  }
  get videoStreamId() {
    return this.videoStream.pipe(map((stream) => (stream && stream.id ? stream.id : null)));
  }
  setVideoStream(stream: VideoStreamModel | null): void {
    this._videoStream.next(stream);
  }

  getCurrentVideoSource() {
    return combineLatest([this.getCurrentMisssionVideoSources(), this._videoStream]).pipe(
      map(
        ([sources, stream]) =>
          sources.find(
            (source) =>
              stream != null && source.author === stream.author && source.device === stream.device
          ) || null
      ),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  getCurrentMisssionVideoSources(): Observable<VideoSourceModel[]> {
    return this.getCurrentMission().pipe(
      switchMap((mission) => this.muxer.getMissionVideoSources(mission.key)),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  private _currentPlaybackTime = new Subject<number>();
  get currentPlaybackTime() {
    return this._currentPlaybackTime.asObservable();
  }
  setCurrentPlaybackTime(time: number): void {
    this._currentPlaybackTime.next(time);
  }

  private _seekingTime = new Subject<number>();
  get seekingTime() {
    return this._seekingTime.asObservable();
  }
  setSeekingTime(time: number): void {
    this._seekingTime.next(time);
  }

  private _hardRefreshRequested = new Subject();
  hardRefresh() {
    return this._hardRefreshRequested.next();
  }
  get hardRefreshRequested() {
    return this._hardRefreshRequested.asObservable().pipe(share());
  }
}
