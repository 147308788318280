import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NbAuthModule } from '@nebular/auth';
import { ThemeModule } from '../@theme/theme.module';
import { SharedComponentsModule } from '../pages/shared-components/shared-components.module';
import { DeniedComponent } from './denied/denied.component';
import { MkLogoComponent } from './mk-logo.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { TosComponent } from './tos/tos.component';

@NgModule({
  imports: [CommonModule, NbAuthModule, ThemeModule, SharedComponentsModule, RouterModule],
  declarations: [
    SignInComponent,
    SignUpComponent,
    ResetPasswordComponent,
    TosComponent,
    DeniedComponent,
    MkLogoComponent,
  ],
})
export class AuthenticationModule {}
