import { OperatorFunction } from 'rxjs';
import { filter } from 'rxjs/operators';

export function isNotNullOrUndefined<T>(value: T | null | undefined): value is NonNullable<T> {
  return value != null;
}

export function filterForNotNullish<T>(): OperatorFunction<T, NonNullable<T>> {
  return (source) => source.pipe(filter(isNotNullOrUndefined));
}
