import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { mapToEntityWithKey } from '../../utils/changes-to-keyed';
import { hasElements } from '../../utils/has-elements';
import { filterForNotNullish, isNotNullOrUndefined } from '../../utils/is-not-null-or-undefined';
import { Destroyable, MixinRoot } from '../../utils/mixins';
import { Perisitor } from '../../utils/persistor';
import { Dict } from '../models/dict';
import { Keyed } from '../models/keyed';
import { Organization } from '../models/organization';
import { DatabaseService } from './database.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService extends Destroyable(MixinRoot) {
  constructor(private db: DatabaseService, private auth: AngularFireAuth) {
    super();

    this.auth.user
      .pipe(
        filterForNotNullish(),
        map((u) => u.uid),
        distinctUntilChanged(),
        switchMap(() => this.getUserOrganizations().pipe(take(1)).toPromise()),
        takeUntil(this.destroyed$)
      )
      .subscribe((userOrgs) => {
        const currentOrgId = this.currOrgIdPersistor.get();

        if (currentOrgId && userOrgs.some((o) => o.key === currentOrgId)) {
          this._currentOrgId.next(currentOrgId);
        } else if (hasElements(userOrgs)) {
          this._currentOrgId.next(userOrgs[0].key);
        }
      });
  }

  // CRUD methods
  // -----------------------------------------------------------------------------------------------------------------------------

  create(organization: Organization) {
    return this.db.organizations.add({
      title: organization.title,
      description: organization.description,
      archivesEnabled: organization.archivesEnabled,
    });
  }

  update(organization: Keyed<Partial<Organization>>): Promise<void> {
    const { key, ...rest } = organization;
    return this.db.organizations.update(key, rest);
  }

  async remove(organizationId: string) {
    const users = await this.db.users.getList().pipe(take(1)).toPromise();
    const organizationUserIds = (users || [])
      .filter((user) => user.organizations?.[organizationId])
      .map((user) => user.uid);

    const removeUsersOrganizations = organizationUserIds.map((uid) =>
      this.db.user(uid).organization(organizationId).remove()
    );

    return Promise.all([
      ...removeUsersOrganizations,
      this.db.organizations.remove(organizationId),
      this.db.organization(organizationId).users.remove(),
    ]);
  }

  private getAllOrganizations(): Observable<Dict<Organization>> {
    return this.db.organizations
      .getDict()
      .valueChanges()
      .pipe(map((orgs) => orgs ?? {}));
  }

  getOrganizationsList(): Observable<Keyed<Organization>[]> {
    return this.getAllOrganizations().pipe(
      map((orgById) =>
        Object.entries(orgById)
          .map(([key, org]) => ({ ...org, key }))
          .sort((a, b) => a.title.localeCompare(b.title))
      )
    );
  }

  getUserOrganizations(): Observable<Keyed<Organization>[]> {
    const userOrganizationIds = this.auth.user.pipe(
      filter(isNotNullOrUndefined),
      switchMap((user) => this.db.user(user.uid).organizations()),
      map((userOrgs) => (userOrgs ? Object.keys(userOrgs) : []))
    );

    return combineLatest([this.getAllOrganizations(), userOrganizationIds]).pipe(
      map(([allOrgs, userOrgIds]) =>
        userOrgIds
          .map((id) => ({ key: id, ...allOrgs[id] }))
          .sort((o1, o2) => o1.title.localeCompare(o2.title))
      )
    );
  }

  // Current Organization
  // -----------------------------------------------------------------------------------------------------------------------------

  private currOrgIdPersistor = new Perisitor<string>('mk-current-org');
  private _currentOrgId = new ReplaySubject<string>(1);

  setCurrentOrganization(organizationId: string): void {
    this._currentOrgId.next(organizationId);
    this.currOrgIdPersistor.set(organizationId);
  }

  get currentOrganizationId() {
    return this._currentOrgId.asObservable();
  }

  get currentOrganization(): Observable<Keyed<Organization>> {
    return this._currentOrgId.pipe(switchMap((id) => this.getOrganization(id)));
  }

  // Private
  // -----------------------------------------------------------------------------------------------------------------------------
  private getOrganization(id: string): Observable<Keyed<Organization>> {
    return this.db.organizations.get(id).snapshotChanges().pipe(mapToEntityWithKey());
  }
}
