<nb-auth-block [ngSwitch]="userRole$ | async">
  <mk-logo size="small"></mk-logo>
  <ng-container *ngSwitchCase="deleted">
    <h2 class="auth__title">Access denied</h2>
    <h4 class="auth__subtitle">Your account has been deleted</h4>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <h2 class="auth__title">Access denied</h2>
  </ng-container>
  <button nbButton status="success" fullWidth size="giant" (click)="goToSignIn()">Sign In</button>
</nb-auth-block>
