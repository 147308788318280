import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { distinctUntilKeyChanged, map } from 'rxjs/operators';

enum Toggle {
  LeftColumen = 'mk:layout-left-column',
  MiddleColumn = 'mk:layout-middle-column',
  RightColumn = 'mk:layout-right-column',
}

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  readonly leftColumn = new ColumnConfig(Toggle.LeftColumen, 15);
  readonly middleColumn = new ColumnConfig(Toggle.MiddleColumn, 50);
  readonly rightColumn = new ColumnConfig(Toggle.RightColumn, 35);

  private _showToggles = new ReplaySubject<boolean>(1);
  get showToggles() {
    return this._showToggles.asObservable();
  }
  setShowToggles(value: boolean): void {
    this._showToggles.next(value);
  }

  private _viewDate = new ReplaySubject<Date>(1);
  get viewDate() {
    return this._viewDate.asObservable();
  }
  setViewDate(value: Date): void {
    this._viewDate.next(value);
  }

  constructor() {
    this._viewDate.next(new Date());
  }
}

interface Config {
  width: number | '*';
  isVisible: boolean;
}
class ColumnConfig {
  private config$: BehaviorSubject<Config>;

  isVisible = () =>
    this.config$.pipe(
      distinctUntilKeyChanged('isVisible'),
      map((config) => config.isVisible)
    );

  width = () =>
    this.config$.pipe(
      distinctUntilKeyChanged('width'),
      map((config) => config.width)
    );

  constructor(private tag: string, defaultWidth: number) {
    const savedValue = localStorage.getItem(tag);
    const initialValue: Config = savedValue
      ? JSON.parse(savedValue)
      : { width: defaultWidth, isVisible: true };
    this.config$ = new BehaviorSubject(initialValue);
  }

  toggle() {
    const newConfig: Config = {
      // eslint-disable-next-line rxjs/no-subject-value
      ...this.config$.value,
      // eslint-disable-next-line rxjs/no-subject-value
      isVisible: !this.config$.value.isVisible,
    };
    this.config$.next(newConfig);
    localStorage.setItem(this.tag, JSON.stringify(newConfig));
  }

  resize(width: number | '*') {
    const newConfig: Config = {
      // eslint-disable-next-line rxjs/no-subject-value
      ...this.config$.value,
      width,
    };
    this.config$.next(newConfig);
    localStorage.setItem(this.tag, JSON.stringify(newConfig));
  }
}
