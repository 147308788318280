import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FileSaverModule} from 'ngx-filesaver';
import {ThemeModule} from '../../@theme/theme.module';
import {MissionToolbarComponent} from './mission-toolbar/mission-toolbar.component';
import {StreamContextMenuComponent} from './swimlanes/stream-context-menu.component';
import {SwimlanesComponent} from './swimlanes/swimlanes.component';

@NgModule({
  declarations: [MissionToolbarComponent, SwimlanesComponent, StreamContextMenuComponent],
  imports: [ThemeModule, FormsModule, ReactiveFormsModule, FileSaverModule],
  exports: [MissionToolbarComponent, SwimlanesComponent],
})
export class SharedComponentsModule {}
