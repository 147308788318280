import { HttpParams } from '@angular/common/http';
import { UserRole } from '../../models/user';
import { AUTH_ROUTE_NAMES } from './auth-route-names';
import { AUTH_ROUTE_QUERY_PARAMS } from './auth-route-query-params';

export const AUTH_URLS = {
  SIGN_IN(): any[] {
    return [AUTH_ROUTE_NAMES.ROOT, AUTH_ROUTE_NAMES.SIGN_IN];
  },

  SIGN_UP_TO_ORGANIZATION(
    organizationId: string,
    organizationName: string,
    invitingUser: string
  ): string {
    const params = new HttpParams()
      .set(AUTH_ROUTE_QUERY_PARAMS.ORGANIZATION_ID, organizationId)
      .set(AUTH_ROUTE_QUERY_PARAMS.ORGANIZATION_NAME, organizationName)
      .set(AUTH_ROUTE_QUERY_PARAMS.INVITING_USER_NAME, invitingUser);
    return `${location.origin}/#/${AUTH_ROUTE_NAMES.ROOT}/${
      AUTH_ROUTE_NAMES.SIGN_UP
    }?${params.toString()}`;
  },

  SIGN_UP_TO_MISSION(
    organizationId: string,
    organizationName: string,
    missionId: string,
    invitingUser: string
  ): string {
    const params = new HttpParams()
      .set(AUTH_ROUTE_QUERY_PARAMS.ORGANIZATION_ID, organizationId)
      .set(AUTH_ROUTE_QUERY_PARAMS.MISSION_ID, missionId)
      .set(AUTH_ROUTE_QUERY_PARAMS.ORGANIZATION_NAME, organizationName)
      .set(AUTH_ROUTE_QUERY_PARAMS.INVITING_USER_NAME, invitingUser);
    return `${location.origin}/#/${AUTH_ROUTE_NAMES.ROOT}/${
      AUTH_ROUTE_NAMES.SIGN_UP
    }?${params.toString()}`;
  },

  TOS(): any[] {
    return [AUTH_ROUTE_NAMES.ROOT, AUTH_ROUTE_NAMES.TOS];
  },

  DENIED(role?: UserRole): any {
    return role
      ? [AUTH_ROUTE_NAMES.ROOT, AUTH_ROUTE_NAMES.DENIED, role]
      : [AUTH_ROUTE_NAMES.ROOT, AUTH_ROUTE_NAMES.DENIED];
  },

  RESET_PASSWORD(): any[] {
    return ['/', AUTH_ROUTE_NAMES.ROOT, AUTH_ROUTE_NAMES.RESET_PASSWORD];
  },
} as const;
