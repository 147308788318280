import { Subject, ReplaySubject, SchedulerLike } from 'rxjs';

export class Perisitor<T> {
  constructor(private key: string) {
    if (Perisitor.keys.has(key)) {
      throw new Error(`Duplicate persistor key: ${key}`);
    }
    Perisitor.keys.add(key);
  }

  static keys = new Set<string>();

  public set(data: T): void {
    try {
      localStorage.setItem(this.key, JSON.stringify(data));
    } catch (e) {
      console.error('Error saving to localStorage', e);
    }
  }

  public get(): T | null {
    try {
      const savedItem = localStorage.getItem(this.key);
      return savedItem ? JSON.parse(savedItem) : null;
    } catch (e) {
      console.error('Error getting data from localStorage', e);
      return null;
    }
  }
}
