import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mapToEntityWithKey } from '../../utils/changes-to-keyed';
import { MissionWithKey } from '../models/keyed';
import { DatabaseService } from './database.service';

// The sole purpose of this service is to break circular dependencies
@Injectable({
  providedIn: 'root',
})
export class FetchMissionService {
  constructor(private db: DatabaseService) {}

  getMission(missionId: string): Observable<MissionWithKey> {
    return this.db.missions.get(missionId).snapshotChanges().pipe(mapToEntityWithKey());
  }
}
