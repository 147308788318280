import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { fromEventPattern } from 'rxjs';
import { filter, map, switchMap, switchMapTo } from 'rxjs/operators';
import { isNotNullOrUndefined } from '../../utils/is-not-null-or-undefined';
import { DatabaseService } from './database.service';

// https://github.com/firebase/firebase-js-sdk/blob/bce35ced7ba0435fabd4259b5d2bab2dc863f74c/packages/database/src/api/Database.ts#L215
const TIMESTAMP = {
  '.sv': 'timestamp'
} as any

@Injectable({
  providedIn: 'root',
})
export class PresenceService {

  constructor(private auth: AngularFireAuth, private db: DatabaseService) {
    const documentVisibilityChange$ = fromEventPattern(
      (handler) => document.addEventListener('visibilitychange', handler),
      (handler) => document.removeEventListener('visibilitychange', handler),
      () => (document.visibilityState === 'visible' ? true : false)
    );

    documentVisibilityChange$
      .pipe(
        switchMap((isVisible) => this.auth.user.pipe(
            filter(isNotNullOrUndefined),
            map((user) => ({ user, isVisible }))
          ))
      )
      .subscribe(({ isVisible, user }) => {
        this.db.users.update({
          uid: user.uid,
          status: isVisible ? 'online' : 'away',
          timestamp: TIMESTAMP,
        });
      });

    const connectivity$ = this.db
      .connected()
      .valueChanges()
      .pipe(filter((x): x is true => x === true));

    connectivity$
      .pipe(switchMapTo(this.auth.user.pipe(filter(isNotNullOrUndefined))))
      .subscribe((user) => {
        this.db.users.update({
          uid: user.uid,
          status: 'online',
          timestamp: TIMESTAMP,
        });
        this.db.users.get(user.uid).query.ref.onDisconnect().update({
          status: 'offline',
          timestamp: TIMESTAMP,
        });
      });
  }
}
