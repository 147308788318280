export const formConfig = {
  redirectDelay: 500,
  validation: {
    password: {
      required: true,
      minLength: 4,
      maxLength: 50,
    },
    email: {
      required: true,
    },
    fullName: {
      required: false,
      minLength: 4,
      maxLength: 50,
    },
  },
};
