export const GROUPS = 'groups';
export const MESSAGES = 'messages';
export const MISSIONS = 'missions';
export const MUXERS_BOX = 'muxers/box';
export const ORGANIZATIONS = 'organizations';
export const ORGANIZATION_GROUPS = 'organizationGroups';
export const ORGANIZATION_USERS = '/organizationUsers';
export const TELEMETRY = '/telemetry';
export const USERS = '/users';
export const USER_MISSIONS = '/userMissions';
