<nb-auth-block [nbSpinner]="submitted">
  <mk-logo size="small"></mk-logo>
  <h2 class="title">
    <section *ngIf="!!message">{{ message }}</section>
    <section>Please sign in with your email</section>
  </h2>
  <nb-alert *ngIf="errors?.length && !submitted" outline="danger" role="alert">
    <p class="alert-title"><b>Oh snap!</b></p>
    <ul class="alert-message-list">
      <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
    </ul>
  </nb-alert>
  <form (ngSubmit)="signIn()" #form="ngForm">
    <div class="form-control-group">
      <input
        name="email"
        placeholder="Email address"
        [(ngModel)]="user.email"
        #email="ngModel"
        required
        pattern=".+@.+\..+"
        autofocus
        nbInput
        [status]="email.invalid && email.touched ? 'danger' : 'success'"
        fullWidth
        fieldSize="giant"
      />
      <ng-container *ngIf="email.invalid && email.touched">
        <p class="caption status-danger" *ngIf="email.errors?.required">Email is required!</p>
        <p class="caption status-danger" *ngIf="email.errors?.pattern">
          Email should be the real one!
        </p>
      </ng-container>
    </div>
    <div class="form-control-group">
      <input
        name="password"
        type="password"
        placeholder="Password"
        [(ngModel)]="user.password"
        #password="ngModel"
        required
        [minlength]="validation.password.minLength"
        [maxlength]="validation.password.maxLength"
        nbInput
        [status]="password.invalid && password.touched ? 'danger' : 'success'"
        fullWidth
        fieldSize="giant"
      />
      <ng-container *ngIf="password.invalid && password.touched">
        <p class="caption status-danger" *ngIf="password.errors?.required">Password is required!</p>
        <p
          class="caption status-danger"
          *ngIf="password.errors?.minlength || password.errors?.maxlength"
        >
          Password should contains from {{ validation.password.minLength }} to
          {{ validation.password.maxLength }} characters
        </p>
      </ng-container>
    </div>
    <button nbButton status="success" size="giant" fullWidth [disabled]="submitted || !form.valid">
      Sign In
    </button>
  </form>
  <section class="another-action">
    <a [routerLink]="resetPasswordLink">Forgot password?</a>
  </section>
</nb-auth-block>
