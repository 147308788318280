import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ids } from '../../../../e2e/ids';

export interface ConfirmDialogResult {
  confirm: boolean;
}

@Component({
  selector: 'mk-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  @Input() public title: string | undefined;
  @Input() public message: string | undefined;
  @Input() public confirmText = 'Ok';
  @Input() public cancelText = 'Cancel';

  ids = ids.confirm_dialog;

  constructor(public dialogRef: NbDialogRef<ConfirmDialogComponent>) {}

  public confirm() {
    const result: ConfirmDialogResult = { confirm: true };
    this.dialogRef.close(result);
  }

  public cancel() {
    const result: ConfirmDialogResult = { confirm: false };
    this.dialogRef.close(result);
  }
}
