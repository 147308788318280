import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { combineLatest, merge, Observable, of } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Destroyable, MixinRoot } from '../../../../utils/mixins';
import { AppConfigService } from '../../../app.config.service';
import { AuthenticationService } from '../../../authentication/authentication.service';
import { APP_URLS } from '../../../routing/app-urls';
import { ProfileService } from '../../../services/profile.service';
import { StateService } from '../../../services/state.service';
import { LayoutService } from '../../layout/layout.service';
import { MkMenuItem } from '../../mk-menu-item';

@Component({
  selector: 'mk-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent extends Destroyable(MixinRoot) implements OnInit {
  @Input() position = 'normal';
  menuTag = 'HEADER_MENU';
  userName = this.profile.user.pipe(map((u) => u?.name));
  userMenu$: Observable<MkMenuItem[]> | undefined;

  constructor(
    private router: Router,
    private nbMenuService: NbMenuService,
    protected auth: AuthenticationService,
    private sidebarService: NbSidebarService,
    private profile: ProfileService,
    public stateService: StateService,
    public layoutService: LayoutService,
    public appConfig: AppConfigService
  ) {
    super();
  }

  ngOnInit() {
    const logout: MkMenuItem = {
      title: 'Log Out',
      action: () => this.auth.logout(),
    };
    const organizations: MkMenuItem = {
      title: 'Organizations',
      link: 'pages/admin/organizations',
    };
    const videoSources: MkMenuItem = {
      title: 'Video Sources',
      link: 'pages/admin/video-sources',
    };
    const userManagement: MkMenuItem = {
      title: 'User Management',
      link: 'pages/admin/users',
    };

    this.userMenu$ = merge(
      of([logout]),
      combineLatest([this.profile.isAdmin, this.profile.isSuperUser]).pipe(
        map(([isAdmin, isSuperuser]) => {
          if (isSuperuser) {
            return [userManagement, organizations, videoSources, logout];
          }
          if (isAdmin) {
            return [userManagement, logout];
          }
          return [logout];
        })
      )
    );

    this.nbMenuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === this.menuTag),
        map(({ item }) => item as MkMenuItem),
        takeUntil(this.destroyed$)
      )
      .subscribe((item) => {
        item.action?.();
      });
  }

  toggleSidebar() {
    this.sidebarService.toggle(false, 'menu-sidebar');
  }

  goToHome() {
    this.router.navigate(APP_URLS.ROOT());
  }
}
