import { Injectable } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { map } from 'rxjs/operators';
import { ConfirmDialogComponent, ConfirmDialogResult } from './confirm-dialog.component';

@Injectable()
export class ConfirmDialogService {
  constructor(private dialog: NbDialogService) {}

  public prompt(title: string, message: string, confirmText: string = 'Ok', cancelText = 'Cancel') {
    return this.dialog
      .open(ConfirmDialogComponent, {
        context: {
          title,
          message,
          confirmText,
          cancelText,
        },
      })
      .onClose.pipe(map((r: ConfirmDialogResult) => !!r?.confirm));
  }
}
