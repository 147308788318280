import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PAGES_URLS } from '../../pages/routing/pages-urls';
import { APP_URLS } from '../../routing/app-urls';
import { AuthenticationService } from '../authentication.service';
import { formConfig } from '../form-config';
import { AUTH_ROUTE_QUERY_PARAMS } from '../routing/auth-route-query-params';
import { AUTH_URLS } from '../routing/auth-urls';

@Component({
  selector: 'mk-sign-in',
  styleUrls: ['./sign-in.component.scss', '../shared/auth.scss'],
  templateUrl: './sign-in.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInComponent {
  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private ref: ChangeDetectorRef
  ) {
    this.title.setTitle('Sign in - Mission Keeper');
  }

  submitted = false;
  errors: string[] = [];
  user = { email: '', password: '' };
  validation = formConfig.validation;
  message: string | undefined = this.route.snapshot.queryParams[AUTH_ROUTE_QUERY_PARAMS.MESSAGE];
  alreadyRegistered = !!this.route.snapshot.queryParams[AUTH_ROUTE_QUERY_PARAMS.ALREADY_REGISTERED];
  missionId: string | undefined = this.route.snapshot.queryParams[
    AUTH_ROUTE_QUERY_PARAMS.MISSION_ID
  ];
  organizationId: string | undefined = this.route.snapshot.queryParams[
    AUTH_ROUTE_QUERY_PARAMS.ORGANIZATION_ID
  ];

  get resetPasswordLink() {
    return AUTH_URLS.RESET_PASSWORD();
  }

  async signIn() {
    this.errors = [];
    this.submitted = true;

    try {
      const credentials = await this.auth.signIn(this.user.email, this.user.password);
      if (this.alreadyRegistered) {
        await this.auth.updateSignUpData(
          credentials.user!.uid,
          this.organizationId!,
          this.missionId
        ); // TODO: remove ! assertion
      }
      if (this.missionId) {
        await this.router.navigate(PAGES_URLS.MISSION(this.missionId));
      } else {
        await this.router.navigateByUrl(
          this.route.snapshot.queryParams[AUTH_ROUTE_QUERY_PARAMS.RETURN_URL] ?? APP_URLS.ROOT()
        );
      }
    } catch (err) {
      console.error(err);
      this.errors = [err];
    } finally {
      this.submitted = false;
      this.ref.markForCheck();
    }
  }
}
